var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseModal',{ref:"modal",attrs:{"title":"Snel aanmaken van ruimtes","max-width":"tw-max-w-4xl"},on:{"hide":_vm.resetForm}},[_c('FormulateForm',{attrs:{"name":"roomQuickEntry","invalid-message":"Gelieve de verplichte velden correct in te vullen."},on:{"submit":_vm.handleRoomCreate},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isLoading = ref.isLoading;
return [_c('FormulateInput',{attrs:{"type":"group","name":"roomtypes","repeatable":true,"validation":"required","group-repeatable-class":"tw-relative tw-shadow-card tw-rounded-md tw-p-4 tw-mb-8"},scopedSlots:_vm._u([{key:"addmore",fn:function(ref){
var addMore = ref.addMore;
return [_c('button',{staticClass:"\n            tw-px-2 tw-py-0.5 tw-border\n            tw-text-success tw-border-success tw-rounded-md tw-shadow-card tw-bg-white\n            hover:tw-bg-success hover:tw-text-white\n            disabled:tw-bg-gray-cc disabled:tw-text-white disabled:tw-border-gray-cc disabled:tw-cursor-not-allowed\n          ",attrs:{"type":"button","title":"+ Kamertype"},on:{"click":addMore}},[_c('i',{staticClass:"far fa-plus"}),_vm._v(" Kamertype ")])]}},{key:"remove",fn:function(ref){
var index = ref.index;
var removeItem = ref.removeItem;
return [_c('button',{staticClass:"\n            tw-px-1.5 tw-py-0.5 tw-border\n            tw-absolute tw-right-0 tw-top-0 tw-translate-x-1/2 tw--translate-y-1/2\n            tw-text-danger tw-border-danger tw-rounded-full tw-shadow-card tw-bg-white\n            hover:tw-bg-danger hover:tw-text-white\n            disabled:tw-bg-gray-cc disabled:tw-text-white disabled:tw-border-gray-cc disabled:tw-cursor-not-allowed\n          ",attrs:{"type":"button","title":"Kamertype en gekoppelde ruimtes verwijderen"},on:{"click":function($event){return removeItem(index)}}},[_c('i',{staticClass:"fas fa-trash"})])]}}],null,true)},[_c('FormulateInput',{attrs:{"name":"type","label":"Type","type":"select","options":_vm.PROPERTY_ROOM_TYPES,"validation":"required","outer-class":"tw-mb-4 tw-max-w-[200px]"}}),_c('FormulateInput',{attrs:{"type":"group","name":"surfaces","label":"Voeg ruimtes toe","validation":"required","show-required-label":true,"repeatable":true,"label-class":['tw-mb-4'],"group-repeatable-class":"tw-relative tw-shadow-card tw-rounded-md tw-p-4","grouping-class":"tw-grid sm:tw-grid-cols-2 md:tw-grid-cols-3 lg:tw-grid-cols-4 tw-gap-8","outer-class":"tw-my-0"},scopedSlots:_vm._u([{key:"addmore",fn:function(ref){
var addMore = ref.addMore;
return [_c('button',{staticClass:"\n              tw-px-2 tw-py-0.5 tw-border tw-mt-6\n              tw-text-success tw-border-success tw-rounded-md tw-shadow-card tw-bg-white\n              hover:tw-bg-success hover:tw-text-white\n              disabled:tw-bg-gray-cc disabled:tw-text-white disabled:tw-border-gray-cc disabled:tw-cursor-not-allowed\n            ",attrs:{"type":"button","title":"+ Ruimte"},on:{"click":addMore}},[_c('i',{staticClass:"far fa-plus"}),_vm._v(" Ruimte ")])]}},{key:"remove",fn:function(ref){
var index = ref.index;
var removeItem = ref.removeItem;
return [_c('button',{staticClass:"\n              tw-px-1.5 tw-py-0.5 tw-border\n              tw-absolute tw-right-0 tw-top-0 tw-translate-x-1/2 tw--translate-y-1/2\n              tw-text-danger tw-border-danger tw-rounded-full tw-shadow-card tw-bg-white\n              hover:tw-bg-danger hover:tw-text-white\n              disabled:tw-bg-gray-cc disabled:tw-text-white disabled:tw-border-gray-cc disabled:tw-cursor-not-allowed\n            ",attrs:{"type":"button","title":"Ruimte verwijderen"},on:{"click":function($event){return removeItem(index)}}},[_c('i',{staticClass:"fas fa-trash"})])]}}],null,true)},[_c('FormulateInput',{attrs:{"type":"number","name":"surface","min":"0","value":"0","label":"Oppervlakte (m²)","placeholder":"(m²)","validation":"bail|required|min:0","outer-class":"tw-my-0"}})],1)],1),_c('FormulateErrors',{staticClass:"tw-text-right"}),_c('div',{staticClass:"tw-flex tw-justify-end"},[_c('FormulateInput',{attrs:{"type":"submit","title":"Opslaan","disabled":isLoading}},[_c('i',{class:[
            'fas tw-mr-1',
            isLoading ? 'fa-spinner-third fa-spin' : 'fa-save'
          ]}),_vm._v(" Opslaan ")])],1)]}}]),model:{value:(_vm.rooms),callback:function ($$v) {_vm.rooms=$$v},expression:"rooms"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }