<template>
  <div class="tw-p-4">
    <div class="tw-flex tw-justify-end tw-gap-2">
      <button
        type="button"
        title="Toevoegen"
        class="tw-px-2 tw-py-0.5 tw-rounded tw-bg-success tw-text-white hover:tw-bg-darken-success"
        @click="showCreateEditModal()"
      >
        <i class="fas fa-plus" /> Ruimte
      </button>
      <button
        type="button"
        title="Meerdere ruimtes"
        class="tw-px-2 tw-py-0.5 tw-rounded tw-bg-gray-500 tw-text-white hover:tw-bg-gray-600"
        @click="showQuickEntryModal"
      >
        <i class="fas fa-plus" /> Meerdere ruimtes aanmaken
      </button>
    </div>

    <div v-for="(rooms, type) in roomTypes" :key="type" class="tw-my-6">
      <h1 class="tw-m-0">
        {{ rooms.length }} {{ type }}
      </h1>

      <div class="tw-my-8 tw-grid lg:tw-grid-cols-2 xl:tw-grid-cols-3 tw-gap-8">
        <div
          v-for="room in rooms"
          :key="room.id"
          class="tw-relative tw-flex tw-gap-2 tw-rounded-md tw-shadow-card tw-flex-grow"
        >
          <div class="tw-absolute tw-flex tw-flex-col tw-gap-3 tw-right-0 tw-top-0 tw--translate-y-2 tw-translate-x-1/2">
            <button
              type="button"
              title="Verwijderen"
              class="action-button tw-text-success tw-border-success hover:tw-bg-success"
              @click="showCreateEditModal(room)"
            >
              <i class="fas fa-pencil" />
            </button>
            <button
              type="button"
              title="Verwijderen"
              :disabled="deleting[room.id]"
              class="action-button tw-text-danger tw-border-danger hover:tw-bg-danger"
              @click="deleteRoom(room)"
            >
              <i class="fas fa-trash" />
            </button>
          </div>

          <div class="tw-w-32 tw-overflow-hidden tw-rounded-l-md tw-flex-shrink-0">
            <img
              v-if="room.picture"
              :src="room.picture.url_thumbnail"
              class="tw-h-full tw-w-full tw-object-cover"
            >
            <img
              v-else
              src="https://iam-media-prod.s3.eu-central-1.amazonaws.com/property_dummy.gif"
              class="tw-h-full tw-w-full tw-object-cover"
            >
          </div>

          <div class="tw-p-2">
            <h4 class="tw-mb-1 tw-text-xs">
              {{ room.type_display }}
              -
              <span class="tw-my-1">
                {{ room.surface }} m<sup>2</sup>
              </span>
            </h4>

            <strong>{{ roomTitle(room) || 'geen titel' }}</strong>
            <p :title="roomDescription(room)" class="tw-mb-0 tw-italic">
              {{ roomDescription(room) | truncate(90) }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <NewPropertyRoomQuickEntryModal
      ref="quickEntryModal"
      @reload="loadPropertyRooms"
    />
    <PropertyRoomCreateEditModal
      ref="createEditModal"
      @reload="loadPropertyRooms"
    />
  </div>
</template>

<script>
import NewPropertyRoomQuickEntryModal from '@/components/properties/NewPropertyRoomQuickEntryModal'
import PropertyRoomCreateEditModal from '@/components/properties/PropertyRoomCreateEditModal'

import { errorModal, questionModal, successModal } from '@/modalMessages'
import { getPropertyRooms, deletePropertyRoom } from '@/services/properties'

export default {
  name: 'PropertyRooms',
  components: {
    NewPropertyRoomQuickEntryModal,
    PropertyRoomCreateEditModal
  },
  data () {
    return {
      roomTypes: {},
      deleting: {}
    }
  },
  computed: {
    propertyId () {
      return this.$route.params.id
    }
  },
  created () {
    this.loadPropertyRooms()
  },
  methods: {
    showQuickEntryModal () {
      this.$refs.quickEntryModal.show()
    },
    showCreateEditModal (room) {
      this.$refs.createEditModal.show(room)
    },
    roomTitle (room) {
      const title = room?.descriptions?.filter((desc) => desc.language === 'nl')
      if (title?.length) return title[0].title
      return ''
    },
    roomDescription (room) {
      const description = room?.descriptions?.filter((desc) => desc.language === 'nl')
      if (description?.length) return description[0].description
      return ''
    },

    async loadPropertyRooms () {
      const response = await getPropertyRooms(this.propertyId)
      const results = response.data?.results || []
      this.roomTypes = {}
      results.forEach(room => {
        const roomType = this.roomTypes[room.type_display]
        if (roomType) roomType.push(room)
        else this.$set(this.roomTypes, room.type_display, [room])
      })
      return response
    },
    async deleteRoom (room) {
      try {
        const result = await questionModal('Weet je zeker dat je deze ruimte wil verwijderen?')
        if (!result.value) return

        this.$set(this.deleting, room.id, true)
        const response = await deletePropertyRoom(this.propertyId, room.id)
        await this.loadPropertyRooms()
        successModal(`${room.type_display} met titel ${this.roomTitle(room)} is verwijderd.`)
        return response
      } catch {
        errorModal('Er ging iets mis bij het verwijderen van de ruimte.')
      } finally {
        this.$set(this.deleting, room.id, false)
      }
    }
  }
}
</script>

<style>
.action-button {
  @apply tw-bg-white tw-px-1.5 tw-py-0.5 tw-border tw-rounded-full tw-shadow-card hover:tw-text-white;
  @apply disabled:tw-bg-gray-cc disabled:tw-text-white disabled:tw-border-gray-cc disabled:tw-cursor-not-allowed;
}
</style>
