<template>
  <BaseModal ref="modal" title="Nieuwe kamer" @hide="resetForm">
    <FormulateForm
      #default="{ isLoading }"
      v-model="values"
      name="roomCreateEditForm"
      invalid-message="Gelieve de verplichte velden correct in te vullen."
      @submit="submit"
    >
      <div class="tw-my-4 tw-grid md:tw-grid-cols-2 tw-gap-4">
        <FormulateInput
          name="type"
          label="Type"
          type="select"
          :options="PROPERTY_ROOM_TYPES"
          validation="required"
          outer-class="tw-my-0"
        />
        <FormulateInput
          type="number"
          name="surface"
          min="0"
          value=0
          label="Oppervlakte (m²)"
          placeholder="(m²)"
          validation="bail|required|min:0"
          outer-class="tw-my-0"
        />
      </div>

      <div class="tw-my-4 tw-grid md:tw-grid-cols-2 tw-items-center tw-gap-4">
        <FormulateInput
          type="file"
          name="picture"
          label="Upload foto"
          :uploader="uploadImage"
          :upload-area-mask-class="['tw-h-10 tw-mt-1.5 !tw-rounded-sm !tw-border-0.5']"
          :file-class="['tw-h-10 !tw-mt-1']"
          element-class="formulate-input-element formulate-input-element--file"
          wrapper-class="formulate-input-wrapper"
          outer-class="formulate-input tw-flex-grow"
          @file-removed="pictureDisplayUrl = null"
        >
          <template #file="context">
            <BaseFileUploadDisplay
              v-bind="context"
              :is-image="true"
              :show-remove-file="true"
            />
          </template>
        </FormulateInput>
        <FormulateInput
          type="button"
          :input-class="['tw-w-full']"
          outer-class="tw-flex-grow"
          @click="openPictureModal"
        >
          of kies foto
        </FormulateInput>
      </div>
      <img
        v-if="values.picture && values.picture.url || pictureDisplayUrl"
        :src="values.picture.url || pictureDisplayUrl"
        alt="Ruimte foto"
        class="tw-w-full tw-h-56 tw-object-cover"
      />

      <Tabs :tabs="LANG_TABS" class="tw-my-4">
        <div v-for="lang in ['nl', 'fr', 'en']" :key="lang" :slot="lang">
          <FormulateInput
            type="text"
            :name="`title_${lang}`"
            label="Titel"
            placeholder="Titel"
            :outer-class="['tw-mt-0']"
          />
          <FormulateInput
            type="textarea"
            :name="`description_${lang}`"
            label="Omschrijving"
            placeholder="Omschrijving"
            :outer-class="['tw-mb-0']"
          />
        </div>
      </Tabs>

      <FormulateErrors class="tw-text-right" />

      <div class="tw-flex tw-justify-end">
        <FormulateInput
          type="submit"
          title="Opslaan"
          :disabled="isLoading"
        >
          <i
            :class="[
              'fas tw-mr-1',
              isLoading ? 'fa-spinner-third fa-spin' : 'fa-save'
            ]"
          />
          Opslaan
        </FormulateInput>
      </div>
    </FormulateForm>

    <BaseModal ref="pictureModal" title="Selecteer foto"  max-width="tw-max-w-7xl" @hide="pictures = { results: [] }">
      <div class="tw-grid tw-grid-flow-row tw-grid-cols-2 md:tw-grid-cols-3 lg:tw-grid-cols-4 tw-gap-4">
        <label
          v-for="picture in pictures.results"
          :key="`picture_${picture.id}`"
          :for="`picture_${picture.id}`"
          :class="[
            'tw-cursor-pointer tw-h-48 tw-w-full',
            { 'tw-ring-4 tw-ring-primary': (values.picture && values.picture.id) === picture.id }
          ]"
        >
          <img
            v-if="picture.url"
            :src="picture.url"
            alt="Pand foto"
            class="tw-h-full tw-w-full tw-object-cover"
          />
          <img
            v-else
            src="https://iam-media-prod.s3.eu-central-1.amazonaws.com/property_dummy.gif"
            class="tw-h-full tw-w-full tw-object-cover"
          >
          <input
            v-model="values.picture"
            :id="`picture_${picture.id}`"
            :value="picture"
            type="radio"
            name="picture"
            class="tw-sr-only"
          />
        </label>
      </div>

      <InfiniteLoading
        key="infiniteLoader"
        spinner="waveDots"
        @infinite="infiniteLoad"
      >
        <div slot="no-more"><!-- Empty div to not render anything --></div>
        <template #no-results>Geen foto's gevonden</template>
        <template #error="{ trigger }">
          Fout bij het laden van foto's,
          <button type="button" class="link tw-font-semibold" @click.prevent="trigger">
            probeer het opnieuw.
          </button>
        </template>
      </InfiniteLoading>

      <div class="tw-flex tw-justify-end">
        <FormulateInput
          type="button"
          title="Opslaan"
          outer-class="tw-mt-4"
          @click="closePictureModal"
        >
          <i class="tw-mr-2 fas fa-save" />
          Opslaan
        </FormulateInput>
      </div>
    </BaseModal>
  </BaseModal>
</template>

<script>
import { parseFileObj } from '@/utils/helpers'
import { successModal } from '@/modalMessages'
import { PROPERTY_ROOM_TYPES } from '@/forms/selectOptions'

import Tabs from '@/components/Tabs.vue'
import InfiniteLoading from 'vue-infinite-loading'

import {
  addPropertyRoom,
  updatePropertyRoom,
  getPropertyPictures,
  uploadPropertyPicture
} from '@/services/properties'

export default {
  name: 'PropertyRoomCreateEditModal',
  components: {
    Tabs,
    InfiniteLoading
  },
  constants: {
    PROPERTY_ROOM_TYPES,
    LANG_TABS: [
      { name: 'NL', target: 'nl' },
      { name: 'FR', target: 'fr' },
      { name: 'EN', target: 'en' }
    ]
  },
  computed: {
    propertyId () {
      return this.$route.params.id
    }
  },
  data () {
    return {
      edit: false,
      values: {
        picture: null
      },
      pictures: {
        results: []
      },
      // pictureDisplayUrl is used to display the picture when it is uploaded using an input, as picture.url is used for picture selection.
      pictureDisplayUrl: null
    }
  },
  methods: {
    show (room) {
      if (room) {
        this.edit = true
        this.pictureDisplayUrl = null
        const { descriptions, ...values } = room
        descriptions.forEach(({ description, title, language }) => {
          values[`title_${language}`] = title
          values[`description_${language}`] = description
        })
        this.values = values
      }
      this.$refs.modal.show()
    },
    resetForm () {
      this.$formulate.reset('roomCreateEditForm')
      this.values = {}
    },
    openPictureModal () {
      return this.$refs.pictureModal.show()
    },
    closePictureModal () {
      return this.$refs.pictureModal.hide()
    },

    async infiniteLoad ($infinite, next) {
      try {
        const payload = { propertyId: this.propertyId }
        if (next) payload.url = next
        const pictures = (await getPropertyPictures(payload)).data
        const results = [...this.pictures.results, ...pictures?.results]
        this.pictures = { ...pictures, results }
        if (results.length) $infinite.loaded()
        if (!pictures?.next) $infinite.complete()
        return pictures
      } catch (error) {
        $infinite.error()
        console.error(error)
      }
    },
    async uploadImage (file, progress) {
      try {
        this.pictureDisplayUrl = null
        progress(0)
        const formData = new FormData()
        formData.append('file', file)
        progress(40)
        const response = await uploadPropertyPicture(this.propertyId, formData)
        progress(100)

        const parsedFile = parseFileObj(response.data)
        this.pictureDisplayUrl = parsedFile.url
        return [parsedFile]
      } catch (error) {
        this.$formulate.handle(error, 'roomCreateEditForm')
      }
    },
    async submit (data) {
      try {
        const { picture, ...values } = data
        values.picture = picture.id || picture[0]?.[0]?.id
        const response = this.edit
          ? await updatePropertyRoom(this.propertyId, data.id, values)
          : await addPropertyRoom(this.propertyId, values)
        successModal(`De ruimte is ${this.edit ? 'bijgewerkt' : 'aangemaakt'}`)
        this.$emit('reload')
        this.$refs.modal.hide()
        return response
      } catch (error) {
        this.$formulate.handle(error, 'roomCreateEditForm')
      }
    }
  }
}
</script>
