<template>
  <div>
    <ul class="tw-flex tw-flex-wrap">
      <li
        v-for="(tab, index) in tabs"
        :key="`tab-button-${index}`"
        :data-active-tab="isTabActive(tab.target)"
      >
        <button
          type="button"
          :title="tab.name"
          :class="[
            'tw-px-6 tw-py-2 tw-rounded-t tw-font-semibold hover:tw-underline focus:tw-underline',
            { 'tw-bg-white tw-shadow-lg': isTabActive(tab.target) }
          ]"
          @click.stop="selectTab(tab.target)"
        >
          <slot name="tab-button" v-bind:tab="tab">
            {{ tab.name }}
          </slot>
        </button>
      </li>
    </ul>
    <ul class="tw-flex">
      <li
        v-for="(tab, index) in tabs"
        v-show="isTabActive(tab.target)"
        :key="`tab-content-${index}`"
        :data-active-tab-content="isTabActive(tab.target)"
        class="tw-p-6 tw-bg-white tw-rounded tw-rounded-tl-none tw-flex-grow tw-shadow-lg"
      >
        <slot :name="tab.target" v-bind:tab="tab">
          {{ tab.name }}
        </slot>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Tabs',
  props: {
    tabs: {
      type: Array,
      default: () => [
        { name: 'Tab 1', target: 'tab-1' }
      ]
    },
    selectedTab: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      activeTarget: null
    }
  },
  created () {
    this.activeTarget = this.selectedTab || this.tabs[0].target
  },
  methods: {
    selectTab (target) {
      this.activeTarget = target
      this.$emit('tabSelected', target)
    },
    isTabActive (target) {
      return this.activeTarget === target
    }
  }
}
</script>
